/**
 * Found this on Stackoverflow https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
 * Shuffles array in place. ES6 version
 * @param {Array} a - An array containing the items.
 */
// const shuffle = (a) => {
// 	for (let i = a.length - 1; i > 0; i--) {
// 		const j = Math.floor(Math.random() * (i + 1));
// 		[a[i], a[j]] = [a[j], a[i]];
// 	}
// 	return a;
// };

// export const createHatches = () => {
//   const hatchArray = new Array(24).fill(0).map((_, i) => ({
//     id: `hatch-${i}`,
//     nr: i + 1,
//     img: `./img/${i + 1}.jpg`,
//     text: `Just ${24 - i - 1} days left`,
//     open: false
//   }));
//   return shuffle(hatchArray);
// };

// Bad one-liners from https://onelinefun.com/christmas
export const hatchArray = [
	{
		id: "hatch-1",
		nr: 1,
		img: "./img/1.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 1</h1><div style=" color: white;"><p>✨️ Je suis ravie de pouvoir t\'accompagner jusqu\'à Noël sur ton bien-être 💖 et que tu en profite merci ! ✨️</p><p>🎋 Si on ta offert ce calendrier et que tu me connais pas voici comment garder le contact avec moi pour continuer à me suivre 🤗</p><p>☯️Ma page Facebook </p><p><a href="https://www.facebook.com/eddegwladys/" style="color: chartreuse;" target="_blank" rel="noopener noreferrer">"Gwladys praticienne énergétique"</a></p><p>💫Mon groupe Facebook échanges & partages</p><p><a href="https://www.facebook.com/groups/1422457541179079/" style="color: chartreuse;" target="_blank" rel="noopener noreferrer">"Gwladys praticienne énergétique"</a></p><p>🌈 Mon 2ème groupe pour mes prestations événements, fidélité : </p><p><a href="https://www.facebook.com/groups/885106789135214/?ref=share" style="color: chartreuse;" target="_blank" rel="noopener noreferrer">"Les âmes fidèles"</a></p><p>🌿Mon insta ancrage et nature</p><p><a href="https://www.instagram.com/gwladys_praticienne/" style="color: chartreuse;" target="_blank" rel="noopener noreferrer">Instagram</a> et TikTok à gwladys praticienne.</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/1.mp4",
		url: "#",
		open: false,
	},
	{
		id: "hatch-2",
		nr: 2,
		img: "./img/2.jpg",
		image: "./data/2.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-3",
		nr: 3,
		img: "./img/3.jpg",
		image: "./data/elcaracol.jpg",
		html: '<h1 style="color:white; text-align: center;">Case 3</h1><div style=" color: white;"><p>Coucou aujourd\'hui pour t\'accompagner dans ta quête du bien être et se faire plaisir.</p><p>Je t\'invite à aller visiter le groupe d\'EL CARACOL Gerald t\'offre 15% de réduction à partir de 40€ d\'achat sur sa gamme d\'ambre boucle d\'oreille et pendentif valable 6 mois 😉 avec le code promo <strong>"Gwladys 20"</strong> Tu es sûr(e) de trouver des pierres et de l\'ambre de qualité, Gérald est un authentique il va lui même au Mexique les chercher, il est de belle énergie et très sympathique sinon je ne recommanderai pas.</p><p>Son lien du groupe : <a style="color:chartreuse" href="https://www.facebook.com/groups/elcaracolmex/?ref=share" target="_blank" rel="noopener noreferrer"> EL CARACOL (Pierres fines et Ambre du Mexique)</a></p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-4",
		nr: 4,
		img: "./img/4.jpg",
		image: "./data/4.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-5",
		nr: 5,
		img: "./img/5.jpg",
		image: "./data/5.jpg",
		html: '<h1 style="color:white; text-align: center;">Case 5</h1><div style=" color: white;"><p>Mantra : </p><p>Tu es la personne qui décide de tout ce que tu fais et tu as toujours le choix</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-6",
		nr: 6,
		img: "./img/6.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 6</h1><div style=" color: white;"><p>🤞 7 conseils pour retrouver un mental de gagnant</p><ul style="list-style-type: none; text-align: left"><li>🍀 Fais toi plaisir</li><li>🍀 Trouve toi une raison d\'être</li><li>🍀 Sois exigeant(e) envers toi même</li><li>🍀 Reste en forme physiquement</li><li>🍀 Entoure toi de personnes de qualité</li><li>🍀 Ancre toi émotionnellement</li><li>🍀 Cultive ton estime de soi</li></ul></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-7",
		nr: 7,
		img: "./img/7.jpg",
		image: "./data/7.jpg",
		html: '<h1 style="color:white; text-align: center;">Case 7</h1><div style=" color: white;"><p>✴️ Mot positif : </p><p><strong>VICTOIRE </strong></p><p>Croire à la victoire est essentiel pour atteindre la réussite dans nos vies,</p><p>dans n\'importe quels domaines.</p><p>Croire en notre capacité à réussir dans tout ce que nous faisons est essentiel.</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-8",
		nr: 8,
		img: "./img/8.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 8</h1><div style=" color: white;"><p>🥳 Pour te faire plaisir et utiliser </p><p>le bon de réduction de 15% valable 1 an </p><p>avec le code promo <strong>"Gwladys 20"</strong></p><p>je t\'invite à rejoindre le groupe : "<a style="color: chartreuse;" href="https://www.facebook.com/groups/839714996771864/?ref=share" target="_blank" rel="noopener noreferrer">Le palais des douceurs </a>" de Gaelle candy </p><p>Le lien de son catalogue : <a style="color: chartreuse;" href="https://www.canva.com/design/DAErylQIPSc/Cy2EnKO_zvKEjLdE3DUmqA/view?utm_content=DAErylQIPSc&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton" target="_blank" rel="noopener noreferrer">Catalogue</a></p><p>Régale toi bien 🍭</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/8.mp4",
		url: "#",
		open: false,
	},
	{
		id: "hatch-9",
		nr: 9,
		img: "./img/9.jpg",
		image: "./data/9.jpg",
		html: '<h1 style="color:white; text-align: center;">Case 9</h1><div style=" color: white;"><p>🎇Compliment :</p><p>Tu es une belle personne ! </p><p>Trouve et dis moi un autre compliment sur toi, </p><p>c\'est important de se complimenter soi même sa rebooste !</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-10",
		nr: 10,
		img: "./img/10.jpg",
		image: "./data/10.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-11",
		nr: 11,
		img: "./img/11.jpg",
		image: "./data/11.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-12",
		nr: 12,
		img: "./img/12.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 12</h1><div style=" color: white;"><p>🎀 Pour te faire plaisir chez Nya bijoux</p><p> 20% de réduction valable 1 an </p><p>avec le code promo <strong>"Gwladys 20" </strong> </p><p>depuis sont site : <a style="color: chartreuse;" href="http://nyabijoux.com">Nyabijoux.com</a></p><p>Tu peux la retrouver sur Facebook et Instagram avec le même nom 🙂</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/12.mp4",
		url: "#",
		open: false,
	},
	{
		id: "hatch-13",
		nr: 13,
		img: "./img/13.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 13</h1><div style=" color: white;"><p>✨️Je t\'invite à faire une relaxation en bol tibétain depuis chez toi,</p><p>avec cette vidéo unique que Virginie Baillet a composé pour toi.</p><p>Merci à elle ! </p><p>Bonne relaxation, méditations en ressourcement positif ✨️</p><a style="color: chartreuse;" href="https://www.facebook.com/virginie.baillet2" target="_blank" rel="noopener noreferrer">Facebook DorLot&Vous</a></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/13.mp4",
		url: "#",
		open: false,
	},
	{
		id: "hatch-14",
		nr: 14,
		img: "./img/14.jpg",
		image: "./data/14.png",
		html: '<h1 style="color:white; text-align: center;">Case 14</h1><div style=" color: white; "><p>🎆 La loi de l’attraction : </p><p>comment obtenir ce que l’on désire dans la vie ?</p><p>Je t\'invite à lire l\'article qui est très intéressant 😇 : </p><p><a style="color: chartreuse;" href="https://www.psychologies.com/Moi/Se-connaitre/Comportement/Interviews/La-loi-de-l-attraction-comment-obtenir-ce-que-l-on-desire-dans-la-vie" target="_blank" rel="noopener noreferrer">Article sur la loi de l\'attraction</a></p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-15",
		nr: 15,
		img: "./img/15.jpg",
		image: "./data/15.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-16",
		nr: 16,
		img: "./img/16.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 16</h1><div style=" color: white;"><p>🍃 10 Conseils Bien être :</p><ul style="list-style-type: none; text-align: left;"><li>✨️On pratique la pensée positive</li><li>✨️On prend l’air</li><li>✨️On cuisine davantage</li><li>✨️On limite les écrans</li><li>✨️On dort suffisamment</li><li>✨️On range son intérieur</li><li>✨️On médite</li><li>✨️On chouchoute son corps</li><li>✨️On reste actif</li><li>✨️On s’autorise à ne rien faire</li></ul></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-17",
		nr: 17,
		img: "./img/17.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 17</h1><div style=" color: white;"><p>💫 Pour toute commande Marina t\'offre 15% de remise à compter de 35€ d\'achat 🙂</p><p>Valable un an avec le code promo <strong>"Gwladys 20"</strong> </p><p>💥Depuis le groupe : Éclats de pierres et de lumières :</p><a style="color: chartreuse;" href="https://www.facebook.com/groups/864383454063741/?ref=share_group_link" target="_blank" rel="noopener noreferrer">Facebook Eclat de pierres et de lumières</a></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/17.mp4",
		url: "#",
		open: false,
	},
	{
		id: "hatch-18",
		nr: 18,
		img: "./img/18.jpg",
		image: "./data/18.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-19",
		nr: 19,
		img: "./img/19.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 19</h1><div style=" color: white;"><p>✴️Créé toi un mantra que tu pourra répéter avant les méditations</p><p>✨️Comment trouver ton mantra ? </p><p>Choisis un mot, une courte déclaration ou une phrase qui exprime ce que tu aimerai être et/ou devenir sans modestie.</p><p>Cela peut ne pas ressembler à res croyances ou à ta réalité actuelles, mais c\'est justement de cette manière là qu\'il sera utile.</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-20",
		nr: 20,
		img: "./img/20.jpg",
		image: "./data/20.gif",
		html: '<h1 style="color:white; text-align: center;">Case 20</h1><div style=" color: white;"><p>💋 Le conseil du jour par Tatiana qui est conseillère chez Rouge séduction, c\'est une personne super positive et pleine de belle énergie ! qui t\'apporte des conseils enrichissant sur le bien être du corp et le plaisir sensuel à 2 ou tout seul...</p><p>Je t\'invite à découvrir son groupe Facebook ou Instagram </p><p><a style="color: chartreuse;" href="https://instagram.com/tatiana_rouge_seduction?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">Instagram </a></p><p><a style="color: chartreuse;" href="https://www.facebook.com/groups/170839120517/?ref=share" target="_blank" rel="noopener noreferrer"> Facebook</a></p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-21",
		nr: 21,
		img: "./img/21.jpg",
		image: "./data/21.jpg",
		html: "<h1 style=\"color:white; text-align: center;\">Case 21</h1><div style=\" color: white; \"><P> 💫 Je t'offre cette plume en protection 💫 </P><p> Active là en pensant très fort à ton ange gardien 😇</P><p>✨️La plume blanche : protection de l'ange gardien</P><ul style=\"list-style-type: none; text-align: left;\"><li>Une plume blanche annonce la présence d'un ange. </li><li>C'est un symbole fort de sa proximité. </li><li>Il t'accompagne au quotidien et t'apporte amour et protection. </li><li>Les plumes blanches sont également signe de spiritualité, paix, espoir et purification. </li><li>Cela dénote d'un lien étroit avec ce messager divin. </li><li>Il est à l'écoute et si tu le contacte, cela peut signifier que ton appel a bien été entendu.</li></ul></div>",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-22",
		nr: 22,
		img: "./img/22.jpg",
		image: "./data/22.jpg",
		html: "",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-23",
		nr: 23,
		img: "./img/23.jpg",
		image: "",
		html: "<h1 style=\"color: white\">✨️Si Noël, c'est la Paix, la Paix doit passer par nos mains. Si Noël, c'est la Lumière, la Lumière doit fleurir en notre vie. Si Noël, c'est la Joie, la Joie doit briller sur nos visages ✨️</h1>",
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
		open: false,
	},
	{
		id: "hatch-24",
		nr: 24,
		img: "./img/24.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 24</h1><div style=" color: white;"><p>✨️ Nous y sommes dernière case et à minuit joyeux Noël 🎅</p><p>Je te souhaite un très jolie réveillon ✨️ </p><p>et je t\'offre .....</p><p>pour savoir regarde la vidéo 🎬</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "./data/24.mp4",
		url: "#",
		open: false,
	},
];

//console.log(hatchArray);
export const createCalendar = () => hatchArray;
