import React, { useState, useEffect } from "react";
import { StyledApp2 } from "./../AppStyle2";
import HatchTest from "../HatchTest";
import { Link } from "react-router-dom";
import calendarsAPI from "../services/calendarsAPI";
import CountDown from "../components/CountDown";
import "semantic-ui-css/semantic.min.css";
import Countdown from "react-countdown";
import axios from "axios";

export default function Calendars() {
	const [step, setStep] = useState(0);
	const [calendarId, setCalendarId] = useState("");
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a complete state
			return (
				<Link to='/calendar'>
					<p
						style={{
							marginTop: "70px",
							color: "greenyellow",
							textShadow: "2px 2px 3px black, 0 0 1.2em white, 0 0 0.6em white",
						}}
					>
						Cliquer pour accéder au calendrier
					</p>
				</Link>
			);
		} else {
			// Render a countdown
			return (
				<>
					<p
						style={{
							color: "greenyellow",
							marginTop: "20px",
							textShadow: "2px 2px 3px black, 0 0 1.2em white, 0 0 0.6em white",
						}}
					>
						{" "}
						Disponible dans :
					</p>
					<span
						style={{
							color: "greenyellow",
							textShadow: "2px 2px 3px black, 0 0 1.2em white, 0 0 0.6em white",
						}}
					>
						{days} Jrs {hours} Hrs {minutes} Mins {seconds} Secs
					</span>
				</>
			);
		}
	};
	useEffect(() => {
		axios.defaults.headers["Authorization"] =
			"Bearer " + localStorage.authToken;

		calendarsAPI.get().then((response) => {
			var nbCalendars = response.data["hydra:member"].length;
			setCalendarId(response.data["hydra:member"][0].id);
			console.log(nbCalendars);
			console.log(response.data["hydra:member"][0].id);
		});
	}, []);

	const handleClickCalendar = (id) => {
		console.log(id);
	};

	return (
		<div
			className='App'
			style={{
				paddingTop: "20px",
			}}
		>
			<div
				style={{
					padding: "10px",
					background: "rgba(0, 0, 0, .5",
				}}
			>
				<h1
					style={{
						textAlign: "center",
						color: "white",
						fontFamily: "Dancing Script, cursive",
						fontSize: "4em",
					}}
				>
					Mes Calendriers
				</h1>
			</div>
			<StyledApp2>
				<div
					style={{
						display: "flex",
						border: "1px solid white",
						padding: "10px",
						borderRadius: "10px",
						width: "400px",
					}}
				>
					<div
						style={{
							padding: "5px",
							borderRadius: "10px",
							width: "195px",
						}}
					>
						<HatchTest sytle={{ width: "50%" }} setStep={setStep} />
					</div>

					<div
						style={{
							border: "1px solid white",
							padding: "5px",
							borderRadius: "10px",
							width: "185px",
							height: "188px",
							backgroundColor: "white",
							opacity: "0.7",
							marginLeft: "5px",
						}}
					>
						{step === 0 && (
							<p>
								<strong>
									Un petit avant goût avant le 1 décembre 😁 pour découvrir ce
									calendrier virtuel que tu peux garder à vie 😉 une case chaque
									jour à ouvrir c'est tout simple. Un premier clic pour ouvrir
									la case vas y clique 👆🏻
								</strong>
							</p>
						)}
						{step === 1 && (
							<p>
								<strong>
									clique sur le bouton vert " surprise " une fenêtre va s'ouvrir
									🥳
								</strong>
							</p>
						)}
					</div>
				</div>
			</StyledApp2>
			<StyledApp2>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						border: "1px solid white",
						padding: "10px",
						borderRadius: "10px",
						width: "400px",
					}}
					//onClick={() => handleClickCalendar(calendarId)}
				>
					<div
						style={{
							height: "100%",
							position: "relative",
							overflow: "hidden",
						}}
					>
						{" "}
						<Link to='/calendar'>
							<img
								object-fit='cover'
								src='/img/calendar.png'
								alt='calendar'
								width='380px'
								height='200px'
							/>
						</Link>
					</div>
				</div>
			</StyledApp2>
		</div>
	);
}
//2022-11-31T23:59:59
