import React, { useState, useEffect } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { toast, ToastContainer } from "react-toastify";
import AuthContext from "./contexts/AuthContext";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import Calendar from "./pages/Calendar";
import Calendars from "./pages/Calendars";
import AuthAPI from "./services/authAPI";

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(
		AuthAPI.isAuthenticated()
	);

	const ProtectedRoute = ({ isAuthenticated, redirectPath = "/" }) => {
		if (!isAuthenticated) {
			return <Navigate to={redirectPath} replace />;
		}
		return <Outlet />;
	};
	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				setIsAuthenticated,
			}}
		>
			<Router>
				<Routes withRouter>
					<Route
						path='/'
						element={<LoginPage history={createBrowserHistory} />}
					/>

					<Route path='/register' element={<RegisterPage />} />

					<Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
						<Route path='/calendar' element={<Calendar />} />
						<Route path='/calendars' element={<Calendars />} />
					</Route>
				</Routes>
			</Router>
			<ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
		</AuthContext.Provider>
	);
}

export default App;
