import axios from "axios";
import { USERS_API } from "../config";

function register(user) {
	return axios.post(USERS_API, user);
}

function get(email) {
	return axios.get(USERS_API + "?email=" + email);
}

export default {
	register,
	get,
};
