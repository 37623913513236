import { StyledHatch } from "./HatchStylesIntro";
import { Modal, Button, Image, Icon } from "semantic-ui-react";
import ReactPlayer from "react-player";
import React, { useState } from "react";
import { useEffect } from "react";

const HatchTest = (props) => {
	const [opena, setOpena] = useState(false);
	const [open, setOpen] = useState(false);

	var hatchData = {
		id: "hatch-0",
		nr: 0,
		img: "./data/0.jpg",
		image: "",
		html: '<h1 style="color:white; text-align: center;">Case 0</h1><div style=" color: white;"><p>Tu y es arrivé 🤗 c\'est pas compliqué 😉	Tu verras sur certaine surprise il y aura des liens  écrit en vert comme ceci : </p><p><a href="https://www.youtube.com/watch?v=3GwjfUFyY6M" style="color: chartreuse;" target="_blank" rel="noopener noreferrer">Vidéo</a></p><p> pour accéder à des sites ou autre vas y tu peux tester clique dessus 🙂</p></div>',
		text: "Clique sur le bouton surprise pour découvrir ton cadeau !",
		video: "",
		url: "#",
	};

	const handleClick = () => {
		setOpen(!open);
		props.setStep(1);
		setOpena(!opena);
	};

	console.log(hatchData);

	return (
		<StyledHatch
			background={hatchData.img}
			onClick={() => handleClick(hatchData.id)}
		>
			<div className={open ? "front open" : "front"}>
				<p>{hatchData.nr}</p>
			</div>
			<div className={open ? "back open" : "back"}>
				<div>
					<div style={{ textAlign: "center" }}>
						<a href={hatchData.url}>
							<p>{hatchData.text}</p>
						</a>
						<Modal
							onClose={() => {
								props.setStep(0);
								setOpen(!open);
								setOpena(false);
							}}
							onOpen={() => setOpena(true)}
							opena={opena}
							centered
							trigger={
								<Button
									color='green'
									style={{ boxShadow: "0px 8px 15px black" }}
								>
									<Icon name='star' color='yellow' />
									Surprise
									<Icon
										name='star'
										color='yellow'
										style={{ marginLeft: "5px" }}
									/>
								</Button>
							}
							closeIcon
							size={"tiny"}
							style={{
								background: "rgba(0, 0, 0, .3",
								textAlign: "center",
							}}
						>
							<Modal.Content
								image
								style={{
									//backgroundColor: "#d6d3c9",
									backgroundImage: `url("./img/hatch_backdrop.jpg")`,
									backgroundSize: "cover",
								}}
							>
								{hatchData.image && !hatchData.html && !hatchData.video ? (
									<Image
										size='large'
										src={hatchData.image}
										style={{ width: "100%" }}
									/>
								) : hatchData.image && hatchData.html ? (
									<Image
										size='large'
										src={hatchData.image}
										style={{ width: "50%", height: "100%" }}
									/>
								) : null}

								{hatchData.html && !hatchData.video && !hatchData.image ? (
									<div
										dangerouslySetInnerHTML={{ __html: hatchData.html }}
										style={{ padding: "20px", width: "100%" }}
									/>
								) : (
									hatchData.html &&
									(hatchData.video || hatchData.image) && (
										<div
											dangerouslySetInnerHTML={{ __html: hatchData.html }}
											style={{ padding: "20px", width: "100%" }}
										/>
									)
								)}

								{hatchData.video && hatchData.html ? (
									<ReactPlayer
										url={hatchData.video}
										width='100%'
										height='50%'
										controls
									/>
								) : (
									hatchData.video && (
										<ReactPlayer
											url={hatchData.video}
											width='60%'
											height='60%'
											controls
										/>
									)
								)}
							</Modal.Content>
						</Modal>
					</div>
				</div>
			</div>
		</StyledHatch>
	);
};

export default HatchTest;
