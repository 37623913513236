import React, { useState, useEffect } from "react";
import { StyledApp } from "./../AppStyles";
import { createCalendar } from "./helpers";
import calendarsAPI from "../services/calendarsAPI";
import "semantic-ui-css/semantic.min.css";
import { Modal, Button } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import axios from "axios";

import Hatch from "./../Hatch";
import usersAPI from "../services/usersAPI";

export default function Calendar(props) {
	const [hatches, setHatches] = useState([]);
	const [opena, setOpena] = useState(false);
	const [calendar, setCalendar] = useState([]);
	const [calendarId, setCalendarId] = useState("");
	const date = new Date();
	const thisDay = date.getUTCDate();
	const thisMonth = date.getUTCMonth() + 1;
	const [badDay, setBadDay] = useState(false);

	useEffect(() => {
		// Could use if statements instead off course
		const calendar = localStorage.calendar
			? JSON.parse(localStorage.calendar)
			: createCalendar();

		setHatches(calendar);
	}, []);

	// Store calendar in localStorage

	useEffect(() => {
		// Could use if statements instead off course
		hatches.length && localStorage.setItem("calendar", JSON.stringify(hatches));
	}, [hatches]);

	useEffect(() => {}, []);

	useEffect(() => {
		axios.defaults.headers["Authorization"] =
			"Bearer " + localStorage.authToken;
		usersAPI.get(localStorage.getItem("email")).then((data) => {
			//console.log(data.data["hydra:member"][0]["calendars"][0]);
			var calendarIri = data.data["hydra:member"][0]["calendars"][0].split("/");
			const calendarIdExt = parseInt(calendarIri[3]);
			setCalendarId(parseInt(calendarIri[3]));

			calendarsAPI.get(calendarIdExt).then((response) => {
				console.log(response.data);
				var day = response.data.day;
				//console.log(day);

				setCalendar(day);

				const hatchesOpen = JSON.parse(localStorage.getItem("calendar"));

				const hatchesOpen2 = hatchesOpen.map((hatche) => {
					let idShort = parseInt(hatche.id.replace("hatch-", ""));

					//console.log(day[idShort - 1]);
					if (
						idShort === day[idShort - 1].id &&
						hatche.open === day[idShort - 1].open
					) {
						//console.log("ok");
						return (
							hatche.id === "hatch-" + idShort && {
								...hatche,
								open: day[idShort - 1].open,
							}
						);
					} else {
						console.log("no");
						return (
							hatche.id === "hatch-" + idShort && {
								...hatche,
								open: day[idShort - 1].open,
							}
						);
					}
				});

				let newHatches = [...hatchesOpen2];

				setHatches(newHatches);
				localStorage.setItem("calendar", JSON.stringify(newHatches));
			});
		});
	}, []);

	const handleFlipHatch = (id, nr) => {
		let idDay = parseInt(id.replace("hatch-", ""));
		//console.log(nr);
		if (
			(idDay === thisDay && thisMonth === 12) ||
			(nr < thisDay && thisMonth === 12)
		) {
			const updatedHatches = hatches.map((hatch) =>
				hatch.id === id ? { ...hatch, open: true } : hatch
			);

			const updateCalendar = calendar.map((day) =>
				day.id === idDay ? { ...day, open: true } : day
			);

			//console.log(updateCalendar);

			setCalendar(updateCalendar);
			setHatches(updatedHatches);

			axios.defaults.headers["Authorization"] =
				"Bearer " + localStorage.authToken;
			let obj2 = { day: updateCalendar };
			//console.log(calendar);
			calendarsAPI.put(calendarId, obj2).then((response) => {
				console.log(response);
			});
		} else {
			setBadDay(true);
			console.log(badDay);
			setOpena(true);
		}
	};

	return (
		<div className='App' style={{ paddingTop: "20px" }}>
			<div
				style={{
					padding: "10px",
					background: "rgba(0, 0, 0, .5",
				}}
			>
				{!isMobile && (
					<h1
						style={{
							textAlign: "center",
							color: "white",
							fontFamily: "Dancing Script, cursive",
							fontSize: "4em",
						}}
					>
						{" "}
						Calendrier bien-être de l'avent
					</h1>
				)}
				{isMobile && (
					<h1
						style={{
							textAlign: "center",
							color: "white",
							fontFamily: "Dancing Script, cursive",
							fontSize: "2em",
						}}
					>
						{" "}
						Calendrier bien-être de l'avent
					</h1>
				)}
			</div>
			<StyledApp>
				{hatches.map((hatch) => (
					<Hatch
						key={hatch.id}
						hatchData={hatch}
						handleClick={handleFlipHatch}
						opena={opena}
						setOpena={setOpena}
						open={hatch.open}
					/>
				))}
			</StyledApp>
			{badDay && (
				<Modal
					onClose={() => {
						setBadDay(false);
					}}
					open={true}
					centered
					closeIcon={true}
					size={"tiny"}
					style={{
						background: "rgba(0, 0, 0, .3",
						textAlign: "center",
					}}
				>
					<Modal.Header>Désolé il faudra attendre demain !!!</Modal.Header>
					<Modal.Content>
						<p>Toi tu es du genre à ouvrir toutes les cases avant la fin !</p>
					</Modal.Content>
				</Modal>
			)}
		</div>
	);
}
