import axios from "axios";

import { CALENDAR_API } from "../config";

function get(calendarId) {
	console.log(CALENDAR_API + "/" + calendarId);
	return axios.get(CALENDAR_API + "/" + calendarId);
}
function put(calendarId, calendar) {
	//console.log(CALENDAR_API + "/" + calendarId);
	return axios
		.put(CALENDAR_API + "/" + calendarId, calendar)
		.then(async (response) => {
			return response;
		});
}

function post(calendar) {
	return axios.post(CALENDAR_API, calendar);
}

export default {
	get,
	put,
	post,
};
