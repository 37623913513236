import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Field from "../components/forms/Field";
import AuthContext from "../contexts/AuthContext";
import { Grid, Button, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../services/authAPI";
import { isMobile } from "react-device-detect";
import styles from "./LoginPage.module.css";

export default function LoginPage() {
	const [credentials, setCredentials] = useState({
		username: "",
		password: "",
	});

	const { setIsAuthenticated } = useContext(AuthContext);
	let navigate = useNavigate();
	const [error, setError] = useState("");

	function handleChange({ currentTarget }) {
		// const newCredentials = { ...credentials };
		// newCredentials[currentTarget.name] = currentTarget.value;
		// setCredentials(newCredentials);

		setCredentials({
			...credentials,
			[currentTarget.name]: currentTarget.value,
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();
		//console.log(credentials);
		try {
			await AuthAPI.authenticate(credentials);
			setError("");
			setIsAuthenticated(true);
			localStorage.setItem("email", credentials.username);
			toast.success("Authentification réussie !");
			console.log("Authentification réussie !");
			navigate("/calendars");
		} catch (error) {
			console.log(error.response);
			setError(
				"Aucun compte ne possède cette adresse ou alors les informations ne correspondent pas"
			);
		}
	}

	return (
		<div className={styles.App}>
			<Grid centered style={{ paddingTop: "40px" }}>
				<Grid
					width={16}
					centered
					style={{
						borderRadius: "5px",
						background: "rgba(0, 0, 0, .5",
						height: "200px",
						width: "100%",
					}}
				>
					{!isMobile && (
						<>
							<Grid.Row centered>
								<h1
									style={{
										textAlign: "center",
										color: "white",
										fontFamily: "Dancing Script, cursive",
										fontSize: "4em",
									}}
								>
									Calendrier bien-être de l'avent
								</h1>
							</Grid.Row>
							<Grid.Row centered>
								<h1
									style={{
										textAlign: "center",
										color: "white",
										fontFamily: "Dancing Script, cursive",
										fontSize: "4em",
									}}
								>
									Page de Connexion
								</h1>
							</Grid.Row>
						</>
					)}
					{isMobile && (
						<>
							<Grid.Row centered>
								<h1
									style={{
										textAlign: "center",
										color: "white",
										fontFamily: "Dancing Script, cursive",
										fontSize: "2em",
									}}
								>
									Calendrier bien-être de l'avent
								</h1>
							</Grid.Row>
							<Grid.Row centered>
								<h1
									style={{
										textAlign: "center",
										color: "white",
										fontFamily: "Dancing Script, cursive",
										fontSize: "2em",
									}}
								>
									Page de Connexion
								</h1>
							</Grid.Row>
						</>
					)}
				</Grid>

				<Grid width={16}>
					<Grid.Row centered>
						<Form
							onSubmit={handleSubmit}
							style={{
								borderRadius: "5px",
								background: "rgba(0, 0, 0, .5",
								padding: "50px",
								height: "300px",
								width: "100%",
							}}
						>
							<Form.Field>
								<Field
									name='username'
									label='Adresse email'
									value={credentials.username}
									onChange={handleChange}
									placeholder='Adresse email de connexion'
									type='email'
									error={error}
								/>
							</Form.Field>

							<Form.Field>
								<Field
									name='password'
									label="Code d'accès"
									value={credentials.password}
									onChange={handleChange}
									placeholder="Votre code d'accès"
									type='password'
								/>
							</Form.Field>

							<div className='form-group'>
								<Button type='submit' className='btn btn-success'>
									Connexion
								</Button>
							</div>
						</Form>
					</Grid.Row>
				</Grid>
			</Grid>
		</div>
	);
}
