import React from "react";
import { StyledHatch } from "./HatchStyles";
import { Modal, Button, Image, Icon } from "semantic-ui-react";
import ReactPlayer from "react-player";

import { isMobile } from "react-device-detect";
const Hatch = ({
	hatchData: { id, nr, text, url, img, image, video, html, open },
	handleClick,
	opena,
	setOpena,
}) => (
	<StyledHatch background={img} onClick={() => handleClick(id, nr)}>
		<div className={open ? "front open" : "front"}>
			<p>{nr}</p>
		</div>
		<div className={open ? "back open" : "back"}>
			<div>
				<div style={{ textAlign: "center" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							fontFamily: "Dancing Script, cursive",
							color: "#fff",
							padding: "10px",
							width: "30%",
							height: "80%",
							borderRadius: "50%",
							background: "rgba(0, 0, 0, 0.5)",
							fontWeight: "700",
							fontSize: "2em",
							margin: "auto",
							textShadow: "2px 2px 3px black, 0 0 1.2em white, 0 0 0.6em white",
						}}
					>
						{nr}
					</div>
					<p style={{ marginBottom: "-5px" }}>{text}</p>

					<Modal
						onClose={() => setOpena(false)}
						onOpen={() => setOpena(true)}
						opena={opena}
						centered
						trigger={
							<Button color='green' style={{ boxShadow: "0px 8px 15px black" }}>
								<Icon name='star' color='yellow' />
								Surprise
								<Icon
									name='star'
									color='yellow'
									style={{ marginLeft: "5px" }}
								/>
							</Button>
						}
						closeIcon
						size={"tiny"}
						style={{
							background: "rgba(0, 0, 0, .3",
							textAlign: "center",
						}}
					>
						<Modal.Content
							image
							style={{
								//backgroundColor: "#d6d3c9",
								backgroundImage: `url("./img/hatch_backdrop.jpg")`,
								backgroundSize: "cover",
							}}
						>
							{image && !html && !video ? (
								<Image size='large' src={image} style={{ width: "100%" }} />
							) : image && html ? (
								<Image
									size='large'
									src={image}
									style={{ width: "50%", height: "100%" }}
								/>
							) : null}

							{html && !video && !image ? (
								<div
									dangerouslySetInnerHTML={{ __html: html }}
									style={{ padding: "20px", width: "100%" }}
								/>
							) : (
								html &&
								(video || image) && (
									<div
										dangerouslySetInnerHTML={{ __html: html }}
										style={{ padding: "20px", width: "100%" }}
									/>
								)
							)}

							{video && html ? (
								<ReactPlayer url={video} width='100%' height='50%' controls />
							) : (
								video && (
									<ReactPlayer url={video} width='60%' height='60%' controls />
								)
							)}
						</Modal.Content>
					</Modal>
				</div>
			</div>
		</div>
	</StyledHatch>
);

export default Hatch;
