import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid, Button, Form, Dropdown } from "semantic-ui-react";
import Field from "../components/forms/Field";
import axios from "axios";
import usersAPI from "../services/usersAPI";
import calendarsAPI from "../services/calendarsAPI";
import { createDays } from "./calendarDays";

export default function RegisterPage({ history }) {
	const [user, setUser] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		passwordConfirm: "",
	});

	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		passwordConfirm: "",
		calendarType: "",
	});

	const [calendarType, setCalendarType] = useState("");

	const calendarOptions = [
		{
			key: "avent",
			text: "Calendrier de l'avent 2022",
			value: "avent",
		},
	];

	function handleChange({ currentTarget }) {
		setUser({ ...user, [currentTarget.name]: currentTarget.value });
	}

	function handleCalendarChange({ currentTarget }) {
		setCalendarType(currentTarget.value);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		const apiErrors = {};
		if (
			user.password !== "" &&
			user.firstName !== "" &&
			user.lastName !== "" &&
			user.email !== "" &&
			user.passwordConfirm !== "" &&
			calendarType !== ""
		) {
			if (user.password !== user.passwordConfirm) {
				apiErrors.passwordConfirm =
					"Les mots de passe ne sont pas identiques !";
				setErrors(apiErrors);
				toast.error("Les mots de passe ne sont pas identiques !");

				return;
			} else {
				try {
					await usersAPI.register(user).then((response) => {
						axios.defaults.headers["Authorization"] =
							"Bearer " + localStorage.authToken;

						let newCalendar = createDays();
						newCalendar.users = ["/api/users/" + response.data.id];
						newCalendar.name = "calendrier de l'avent 2022";
						newCalendar.dateStart = "2022-12-01T00:00:00+00:00";
						newCalendar.dateEnd = "2022-12-24T00:00:00+00:00";

						calendarsAPI.post(newCalendar).then((response) => {
							console.log(response);
						});
						setErrors({});
						toast.success(
							"Vous êtes désormais inscrit, vous pouvez vous connecter !"
						);
					});

					//history.replace("/login");
				} catch ({ response }) {
					const { violations } = response.data;
					if (violations) {
						violations.forEach(({ propertyPath, message }) => {
							apiErrors[propertyPath] = message;
						});
						setErrors(apiErrors);
					}
				}
			}
		} else {
			apiErrors.firstName = "Veuillez renseigner votre prénom";
			apiErrors.lastName = "Veuillez renseigner votre nom";
			apiErrors.email = "Veuillez renseigner votre adresse email";
			apiErrors.password = "Veuillez renseigner un mot de passe";
			apiErrors.passwordConfirm = "Veuillez confirmer votre mot de passe";
			apiErrors.calendarType = "Veuillez choisir un calendrier";
			toast.error(" Veuillez remplir tous les champs !");
		}
	}

	return (
		<>
			<Grid centered className='App' style={{ paddingTop: "40px" }}>
				<Grid
					width={16}
					centered
					style={{
						borderRadius: "5px",
						background: "rgba(0, 0, 0, .5",
						height: "100px",
						width: "100%",
					}}
				>
					<Grid.Row centered>
						<h1
							style={{
								textAlign: "center",
								color: "white",
								fontFamily: "Dancing Script, cursive",
								fontSize: "4em",
							}}
						>
							Création des utilisateurs
						</h1>
					</Grid.Row>
				</Grid>

				<Grid width={16}>
					<Grid.Row centered>
						<Form onSubmit={handleSubmit}>
							<Form.Field>
								<Field
									name='lastName'
									label='Nom'
									placeholder='Votre nom de famille'
									value={user.lastName}
									onChange={handleChange}
									error={errors.lastName}
								/>
							</Form.Field>
							<Form.Field>
								<Field
									name='firstName'
									label='Prénom'
									placeholder='Votre prénom'
									value={user.firstName}
									onChange={handleChange}
									error={errors.firstName}
								/>
							</Form.Field>
							<Form.Field>
								<Field
									name='email'
									label='Adresse email'
									placeholder='Votre adresse email'
									value={user.email}
									onChange={handleChange}
									error={errors.email}
									type='email'
								/>
							</Form.Field>
							<Form.Field>
								<Field
									name='password'
									label='Mot de passe'
									placeholder='Votre mot de passe'
									value={user.password}
									onChange={handleChange}
									error={errors.password}
									type='text'
								/>
							</Form.Field>
							<Form.Field>
								<Field
									name='passwordConfirm'
									label='Confirmer votre mot de passe'
									placeholder='Confirmer votre mot de passe'
									value={user.passwordConfirm}
									onChange={handleChange}
									error={errors.passwordConfirm}
									type='text'
								/>
							</Form.Field>
							<Form.Field>
								<label style={{ color: "white" }}>
									<h3>Choisissez votre calendrier</h3>
								</label>
								<Dropdown
									name='calendarType'
									placeholder='Choisissez votre type de calendrier'
									fluid
									selection
									options={calendarOptions}
									value={calendarOptions.value}
									onChange={handleCalendarChange}
									//error={errors.calendarType}
								/>
							</Form.Field>

							<div className='form-group'>
								<Button type='submit' className='btn btn-success'>
									Créer
								</Button>
								{/* <Link to="/login" className="btn btn-link">
            J'ai déjà un compte
          </Link> */}
							</div>
						</Form>
					</Grid.Row>
				</Grid>
			</Grid>
		</>
	);
}
