import styled from "styled-components";

export const StyledApp2 = styled.div`
	display: flex;
	flex-grow: 4;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	space-around: 20px;
	padding: 20px;
	width: 100%;
`;
