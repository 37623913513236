import React from "react";

export default function Field({
	name,
	label,
	value,
	onChange,
	placeholder = "",
	type = "text",
	error = "",
}) {
	return (
		<div className='form-group'>
			<label htmlFor={name} style={{ color: "white", fontSize: "20px" }}>
				{label}
			</label>
			<input
				type={type}
				placeholder={placeholder || label}
				name={name}
				id={name}
				className={"form-control" + (error ? " is-invalid" : "")}
				value={value}
				onChange={onChange}
			/>
			<p
				className='invalid-feedback'
				style={{ color: "red", fontSize: "20px", backgroundColor: "white" }}
			>
				{error}
			</p>
		</div>
	);
}
