export const calendarStart = {
	day: [
		{
			id: 1,
			open: false,
		},
		{
			id: 2,
			open: false,
		},
		{
			id: 3,
			open: false,
		},
		{
			id: 4,
			open: false,
		},
		{
			id: 5,
			open: false,
		},
		{
			id: 6,
			open: false,
		},
		{
			id: 7,
			open: false,
		},
		{
			id: 8,
			open: false,
		},
		{
			id: 9,
			open: false,
		},
		{
			id: 10,
			open: false,
		},
		{
			id: 11,
			open: false,
		},
		{
			id: 12,
			open: false,
		},
		{
			id: 13,
			open: false,
		},
		{
			id: 14,
			open: false,
		},
		{
			id: 15,
			open: false,
		},
		{
			id: 16,
			open: false,
		},
		{
			id: 17,
			open: false,
		},
		{
			id: 18,
			open: false,
		},
		{
			id: 19,
			open: false,
		},
		{
			id: 20,
			open: false,
		},
		{
			id: 21,
			open: false,
		},
		{
			id: 22,
			open: false,
		},
		{
			id: 23,
			open: false,
		},
		{
			id: 24,
			open: false,
		},
	],
};

export const createDays = () => calendarStart;
